var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalAsignacion",attrs:{"titulo":"Asignar entregas","tamano":"modal-xl","no-aceptar":"","adicional":"Asignar"},on:{"adicional":function($event){return _vm.asignar()}}},[_c('div',{staticClass:"row mx-0 justify-center"},[_c('div',{staticClass:"col-auto mt-3 pt-1 px-2"},[_c('img',{staticClass:"obj-cover bg-light-f5 rounded-circle",attrs:{"src":_vm.deliveryImagen,"width":"38","height":"38"}})]),_c('div',{staticClass:"col-3"},[_c('p',{staticClass:"f-14 text-general pl-3"},[_vm._v("Delivery")]),_c('el-select',{staticClass:"w-100",attrs:{"clearable":true},on:{"change":function($event){return _vm.onChangeDelivery()}},model:{value:(_vm.delivery),callback:function ($$v) {_vm.delivery=$$v},expression:"delivery"}},_vm._l((_vm.deliveries),function(item,i){return _c('el-option',{key:i,attrs:{"label":item.nombre,"value":item.id}})}),1)],1),_c('div',{staticClass:"col-2"},[_c('p',{staticClass:"f-14 text-general pl-3"},[_vm._v("Día")]),_c('el-date-picker',{staticClass:"w-100",attrs:{"type":"date","value-format":"yyyy-MM-dd","format":"dd MMM yyyy","clearable":false},on:{"change":function($event){return _vm.porCedisFecha()}},model:{value:(_vm.fecha),callback:function ($$v) {_vm.fecha=$$v},expression:"fecha"}})],1),_c('div',{staticClass:"col-auto text-general d-middle pt-3"},[_vm._v(" Seleccione los pedidos que se asignarán a este Delivery ")])]),_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-12"},[_c('tabla-general',{ref:"tablaGeneral",attrs:{"data":_vm.pedidos,"activar-seleccion-multiple":"","mostrar-buscador":false,"alto":_vm.altoTabla,"usar-paginacion":false},on:{"seleccion":function (data) { return _vm.onSelect(data); }}},[_c('template',{slot:"adicionales-izquierda"},[_c('el-table-column',{attrs:{"label":"Pedido","class-name":" text-general f-17","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-general2"},[_vm._v(" "+_vm._s(row.id)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Delivery","class-name":"text-center text-general f-17","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-general2"},[_vm._v(" "+_vm._s(row.delivery_nombre)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Vendedor","class-name":"text-center text-general f-17","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-general2"},[_vm._v(" "+_vm._s(row.tienda_nombre)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Cliente","class-name":"text-center text-general f-17","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-general2"},[_vm._v(" "+_vm._s(row.nombre_cliente)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Valor","class-name":"text-center text-general f-17","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-general2"},[_vm._v(" "+_vm._s(_vm.convertMoney(row.valor_final))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Estado","class-name":"text-center text-general f-17","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-general2"},[_vm._v(" "+_vm._s(row.estado)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Entrega","class-name":"text-center text-general f-17","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-general2"},[_vm._v(" "+_vm._s(row.entrega)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Cedis","class-name":"text-center text-general f-17","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-general2"},[_vm._v(" "+_vm._s(row.cedis_nombre)+" ")])]}}])})],1)],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }